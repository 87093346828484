/* eslint no-use-before-define: 0 */
import React, { useState, useEffect } from "react";
import NoResult from "./noresult";
import Ads from "./ads";
import BusinessCard from "./businesscard";
import Loader from "../images/loader.gif";
import { Link } from "gatsby";
import Pagination from "@material-ui/lab/Pagination";
import scrollToSection from "./scroller";

export default function Other({ otherData, slug, loc }) {
  const [data, setData] = useState([]);

  const [display, setDisplay] = useState(true);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setData(otherData);
    setDisplay(false);
  }, [slug, loc]);

  if (data.length === 0) {
    return <NoResult search={slug?.split("-").join(" ")} display={display} />;
  } else {
    return (
      <div className="search-result-section">
        <Ads zoneid={78} />
        <section className="container main-category">
          <div className="row">
            <div className="col-md-12">
              {display ? (
                <p className="loader-wrapper">
                  <img
                    alt="Loader"
                    height="100px"
                    width="100px"
                    style={{ margin: 30 }}
                    src={Loader}
                  />
                </p>
              ) : (
                <p style={{ display: `none` }} className="loader-wrapper">
                  <img
                    alt="Loader"
                    height="100px"
                    width="100px"
                    style={{ margin: 30 }}
                    src={Loader}
                  />
                </p>
              )}
              {slug ? (
                <p className="suggestions">Searching for "{slug}"</p>
              ) : (
                ""
              )}
              {data[0].suggestionurls !== "" ? (
                <p className="suggestions">Matched Suggestions</p>
              ) : (
                ""
              )}

              {data[0].suggestionrelated !== ""
                ? data[0].suggestionrelated?.split(";").map((name, index) => {
                    if (data[0].suggestionurls !== "") {
                      var urls = data[0].suggestionurls.split(";");
                    }
                    if (
                      index ===
                      data[0].suggestionurls.split(";").length - 1
                    ) {
                      return (
                        <Link
                          className="suggestionLinks"
                          to={urls[parseInt(index)]}
                        >
                          {name}
                        </Link>
                      );
                    } else {
                      return (
                        <>
                          <Link
                            className="suggestionLinks"
                            to={
                              loc
                                ? urls[parseInt(index)] +
                                  "/" +
                                  loc?.replace(", ", "-")
                                : urls[parseInt(index)]
                            }
                          >
                            {name}
                          </Link>
                          <span className="suggestionSeparator">, </span>
                        </>
                      );
                    }
                  })
                : ""}

              {data.length !== 0 ? (
                <p
                  style={{
                    paddingTop: 15,
                    paddingBottom: 20,
                    fontSize: 20,
                    fontWeight: `bold`,
                  }}
                >
                  {data.length} result{data.length > 1 && "s"}
                </p>
              ) : (
                ""
              )}

              {data.length > 20 && (
                <h5 className="pt-1 pb-3 font-weight-bold">
                  Showing {20 * (page - 1) + 1} to{" "}
                  {20 * page > data.length ? data.length : 20 * page}
                </h5>
              )}

              {data.slice(20 * (page - 1), 20 * page).map((item, index) => {
                if (index !== 0 && index % 3 === 0) {
                  return (
                    <div className="searchAdv searchAdv78">
                      <Ads zoneid={78} marginAdv={"0 7%"} />
                      <BusinessCard data={item} />
                    </div>
                  );
                } else {
                  return <BusinessCard data={item} />;
                }
              })}
            </div>
          </div>

          {data.length > 20 && (
            <Pagination
              count={Math.ceil(data.length / 20)}
              page={page}
              showFirstButton
              showLastButton
              onChange={(e, val) => {
                scrollToSection("search-result-section");
                setPage(val);
              }}
            />
          )}
        </section>
      </div>
    );
  }
}
