import React from "react";
import SeO from "../components/SEO";
import Header from "../components/header";
import Footer from "../components/footer";
import Other from "../components/other";
import { navigate } from "gatsby";
const queryString = require("query-string");

export default function Others() {
  if (typeof window !== "undefined") {
    if (typeof window.localStorage == "undefined") {
      window.localStorage = new Storage("local");
    }
    const parsed = queryString.parse(window.location.href);

    if (parsed.city && parsed.category) {
      localStorage.setItem("slug", parsed.category);
      localStorage.setItem("new_city_name", parsed.city);
      localStorage.setItem("slug_loc", parsed.city.split(",")[0]);
      localStorage.setItem("new_state_code", "undefined");
      navigate("/others", { replace: true });
      return null;
    } else if (
      localStorage.getItem("slug") &&
      localStorage.getItem("slug_loc") &&
      (localStorage.getItem("new_city_name") ||
        localStorage.getItem("city_name"))
    ) {
      return (
        <div>
          <SeO title="Others" />
          <Header />
          <Other
            slug={localStorage.getItem("slug")}
            loc={localStorage.getItem("slug_loc")}
          />
          <Footer />
        </div>
      );
    } else {
      navigate("/");
      return null;
    }
  } else {
    return true;
  }
}
